import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import axios from "axios";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);

    localStorage.setItem("userEmail", email);
    localStorage.setItem("userName", email.split("@")[0]);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-[90vh] flex flex-col items-center justify-center p-5">
      <div className="bg-white text-black p-10 w-full md:w-2/6   justify-center flex flex-col gap-6">
        <form className="flex flex-col gap-7" onSubmit={(e) => onSubmit(e)}>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
            className="bg-none border-b focus:border-b-2 py-4 px-1"
            required
          />

          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
            minLength="6"
            className="bg-none border-b focus:border-b-2 py-4 px-1"
            required
          />

          <button
            className="flex font-serif tracking-widest flex-row gap-2 justify-between items-end text-xl mt-4 p-3 w-full md:w-1/5 min-w-fit  hover:bg-primaryBg hover:text-white transition duration-300"
            type="submit"
          >
            Login
            <ArrowRightIcon className="h-6 w-6  hover:opacity-70" />
          </button>
        </form>

        <div className="flex flex-col gap-2">
          <p className="text-gray-500 text-xs w-full md:w-3/6 text-center self-center">
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
          <p className="text-gray-500 text-xs w-full md:w-3/6 text-center self-center">
            Forgot your Password?{" "}
            <Link to="/reset-password">Reset Password</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
