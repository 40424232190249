import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const Home = () => (
  <div className="min-h-[90vh] flex flex-col items-center justify-center p-5">
    <div className="bg-white text-black p-10 w-full md:w-2/6 h-2/6 text-center justify-center flex flex-col items-center gap-6">
      <h1 className="text-4xl font-bold">OMEGA</h1>

      <p>Login to proceed</p>
      <hr className="text-black h-2 w-52" />
      <Link to="/authSelect" role="button">
        <ArrowRightIcon className="h-6 w-6 hover:opacity-70" />
      </Link>
    </div>
  </div>
);

export default Home;
