import React, { useState } from "react";
import axios from "axios";
import "./RightSidebar.css";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import PortfolioBuilderPopup from "./PortfolioBuilderPopup"; // Adjust the import path based on your project structure

Chart.register(ArcElement);

const RightSidebar = ({
  cards,
  yourPortfolioChartOptions,
  yourPortfolio,
  apiResponse,
  onCardClick,
}) => {
  // Add 'onCardClick' prop to handle card click event
  const [riskScore, setRiskScore] = useState(50); // Initial value for the risk score
  const [riskLimit, setRiskLimit] = useState(50); // Initial value for the risk limit

  const handleRiskScoreChange = (e) => {
    setRiskScore(e.target.value);
  };

  const handleRiskLimitChange = (e) => {
    setRiskLimit(e.target.value);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const colors = ["#007bff", "#28a745", "#ffc107", "#dc3545"]; // Define colors for the cards and the doughnut chart

  const doughnutChartData = {
    labels: cards.map((card) => card.card_name),
    datasets: [
      {
        data: cards.map((card) => card.allocation_amount),
        backgroundColor: cards.map(
          (card, index) => colors[index % colors.length]
        ), // Use colors array
        hoverBackgroundColor: "#0056b3", // Hover color (you can customize this)
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70, // Adjust the value to set the size of the hole in the middle (70% in this case)
    onClick: (event, elements) => {
      if (elements && elements.length > 0) {
        const clickedIndex = elements[0].index;
        if (cards[clickedIndex]) {
          onCardClick(cards[clickedIndex]);
        }
      }
    },
  };
  function generatePortfolioNumber(userEmail) {
    const randomSuffix = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const prefix = userEmail.slice(0, 10);
    return `${prefix}-${randomSuffix}`;
  }

  const handleBuildClick = () => {
    console.log("Build button clicked"); // Troubleshooting step 1

    const jwtToken = localStorage.getItem("access");
    const userEmail = localStorage.getItem("userEmail");
    console.log("JWT Token:", jwtToken); // Troubleshooting step 2
    const portfolioNumber = generatePortfolioNumber(userEmail);
    // Get the user's email and username from the JWT token payload

    // Filter out cards with allocation_amount greater than 0
    const selectedCards = cards.filter((card) => card.allocation_amount >= 0);

    // Prepare the selected card data to send to the backend
    const cardsData = selectedCards.map((card) => ({
      card_index: card.card_index,
      card_name: card.card_name,
      card_description: card.card_description,
      allocation_amount: card.allocation_amount,
      risk_percentage: card.risk_score,
      email: userEmail,
      portfolio_number: portfolioNumber,
    }));

    axios
      .post("http://localhost:8000/api/save_card_data/", cardsData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(
          "Selected card data sent to backend and saved successfully."
        );
      })
      .catch((error) => {
        if (error.response) {
          console.error(
            "Error sending selected card data to backend:",
            error.response.data
          );
        } else if (error.request) {
          console.error("Error sending request:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  return (
    <div className="">
      {/* Donut Chart */}

      {/* Portfolio Builder Button */}

      {apiResponse && (
        <div className="grid grid-cols-2 items-center gap-3">
          <button
            className="hover:text-black hover:bg-white transition duration-200 p-3"
            onClick={togglePopup}
          >
            Portfolio Builder
          </button>

          <button
            className="hover:text-black hover:bg-white transition duration-200 p-3"
            onClick={handleBuildClick}
          >
            Build
          </button>
        </div>
      )}

      {/* Popup Component */}
      {isPopupOpen && (
        <PortfolioBuilderPopup
          yourPortfolioChartOptions={yourPortfolioChartOptions}
          yourPortfolio={yourPortfolio}
          apiResponse={apiResponse}
          onClose={togglePopup}
        />
      )}

      {/* Build Button */}
    </div>
  );
};

export default RightSidebar;
