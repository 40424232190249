import React, { Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import PortfolioList from "../containers/PortfolioList"; // Import PortfolioList
import DropdownButton from "./DropdownButton";

const Navbar = ({ logout, isAuthenticated }) => {
  const [redirect, setRedirect] = useState(false);

  const logout_user = () => {
    logout();
    setRedirect(true);
  };

  const guestLinks = () => (
    <Fragment>
      <Link
        className="p-2 hover:bg-black hover:text-white transition duration-300"
        to="/login"
      >
        Login
      </Link>

      <Link
        className="p-2 hover:bg-black hover:text-white transition duration-300"
        to="/signup"
      >
        Sign Up
      </Link>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      <Link
        className="p-2 hover:bg-black hover:text-white transition duration-300 w-full"
        to="/cards"
      >
        Cards
      </Link>
      <Link
        className="p-2 hover:bg-black hover:text-white transition duration-300"
        to="/PortfolioList"
      >
        Portfolios
      </Link>
      {/* Add this link */}
      <a
        className="p-2 hover:bg-black hover:text-white transition duration-300"
        href="#!"
        onClick={logout_user}
      >
        Logout
      </a>
    </Fragment>
  );

  return (
    <Fragment>
      <nav>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center text-2xl">
            OMEGA
          </Link>

          <div className="md:block md:w-auto text-2xl" id="navbar-solid-bg">
            <DropdownButton
              auth={isAuthenticated}
              links={isAuthenticated ? authLinks() : guestLinks()}
            />
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
