import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Cards.css";
import RightSidebar from "./RightSidebar";
import Chart from "react-apexcharts";

import {
  Chart as OldChart,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { CategoryScale } from "chart.js";
import Backdrop from "../components/Backdrop";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { NewDoughnut } from "../components/Test";
OldChart.register(CategoryScale);
OldChart.register(LinearScale);
OldChart.register(PointElement);
OldChart.register(LineElement);

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const defaultCards = [
  {
    card_index: 0,
    card_name: "Cash",
    card_description: "Cash available for investment.",
    allocation_amount: 100,
    risk_score: 0,
  },
  {
    card_index: 1,
    card_name: "Equity & Derivatives",
    card_description:
      "Equity refers to ownership in a company, represented by stocks or shares. It is a crucial component of a diversified investment portfolio and can play an important role in long-term wealth creation.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 2,
    card_name: "Investment Grade Bonds",
    card_description:
      "Investment grade bonds are debt securities issued by corporations or governments with a credit rating of BBB- or higher by a credit rating agency.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 3,
    card_name: "Gold",
    card_description: "Gold can be an effective way to diversify a portfolio.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 4,
    card_name: "Money Market",
    card_description:
      "A money market is a segment of the financial market where financial instruments with high liquidity and low volatility, such as Treasury bills, certificates of deposit, and commercial paper, are traded.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 5,
    card_name: "NASDAQ",
    card_description:
      "NASDAQ is a stock exchange based in the United States. It is known for listing technology and internet-based companies, but it also lists companies from other industries. Investing in NASDAQ-listed stocks can offer the opportunity for growth and diversification for investors.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 6,
    card_name: "Real Estate",
    card_description:
      "A Real Estate Investment Trust (REIT) is a type of investment vehicle that invests in income-producing real estate assets, such as office buildings, shopping centers, apartments, hotels etc. REITs can provide investors with a regular income stream and the potential for long-term capital appreciation.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 7,
    card_name: "Emerging Equities",
    card_description:
      "Emerging equities refer to stocks of companies that are based in emerging market countries, such as Brazil, China, and India. It can provide investors with the potential for higher returns compared to developed market equities, due to the higher growth potential of the economies and companies in these countries.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 8,
    card_name: "Commodities",
    card_description:
      "Commodities are raw materials or primary products that are used in the production of goods and services. Examples of commodities include precious metals, energy products, agricultural products, and industrial metals. Investing in commodities can provide investors with the potential for diversification.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 9,
    card_name: "High Yield Bonds",
    card_description:
      "High yield bonds are issued by companies with lower credit ratings, meaning that there is a higher risk of default compared to investment-grade bonds. These bonds pay higher yields compared to investment-grade bonds to compensate for the increased risk of default.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 10,
    card_name: "Cryptocurrency",
    card_description:
      "Cryptocurrency is a type of digital or virtual currency that uses cryptography to secure transactions and control the creation of new units. Bitcoin is the most well-known cryptocurrency. Investing in them can provide the potential for high returns, but it also involves a high level of risk.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 11,
    card_name: "Private Equity",
    card_description:
      "Private equity refers to investment in companies that are not publicly traded on stock exchanges. The investment horizon for private equity is typically longer than for public equity, and the returns are often higher, although the risk is also higher.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 12,
    card_name: "Volatility Index",
    card_description:
      "The Volatility Index (VIX) is a financial index that is used as a measure of market volatility and investor sentiment. The VIX reflects the market's expectation of volatility in the S&P 500 Index over the next 30 days.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 13,
    card_name: "Forex",
    card_description:
      "Forex, also known as the foreign exchange market, is a decentralized global market where all the world's currencies are traded.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 14,
    card_name: "TIPS",
    card_description:
      "Treasury Inflation-Protected Securities (TIPS) are a type of U.S. Treasury bond that provides protection against inflation. TIPS are considered a relatively low-risk investment, as they are backed by the full faith and credit of the U.S. government.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 15,
    card_name: "ESG",
    card_description:
      "Environmental, Social, and Governance (ESG) investing refers to an investment strategy that considers a company's impact on the environment, its treatment of employees, and its overall corporate governance practices.",
    allocation_amount: 0,
    risk_score: 0,
  },
];

const Cards = (onClose) => {
  const colors = ["#007bff", "#28a745", "#ffc107", "#dc3545"];
  const [apiResponse, setApiResponse] = useState(null);
  const [cards, setCards] = useState(defaultCards);
  const [selectedCard, setSelectedCard] = useState(null);
  const [temporaryAllocationAmount, setTemporaryAllocationAmount] = useState(
    cards.map(() => "0")
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [sumAllocationRisk, setSumAllocationRisk] = useState(0); // Initialize with 0
  const calculateSumAllocationRisk = () => {
    const sum = cards.reduce((total, card, index) => {
      if (temporaryAllocationAmount[index] !== "0") {
        const allocation = parseFloat(temporaryAllocationAmount[index]);
        const risk = card.risk_score; // Use card.risk_score instead of riskScore
        return total + (allocation / 100) * risk; // Use risk instead of riskScore
      }
      return total;
    }, 0);
    return sum;
  };
  const [riskScore, setRiskScore] = useState(50);
  const [riskLimit, setRiskLimit] = useState(50);
  const [cashAllocation, setCashAllocation] = useState(100);

  const onCardClick = (card, cardIndex) => {
    setSelectedCard(card);
    console.log(card);
    // Handle card click logic here
  };

  const fetchRiskScore = async (cardIndex) => {
    const proxyUrl = "http://localhost:8000/proxy_api/";
    const apiUrl = "http://46.101.121.174/risk_scores";

    try {
      const riskScoreResponse = await axios.get(proxyUrl, {
        params: { api_url: apiUrl },
      });
      const riskScores = riskScoreResponse.data;
      const riskScore = riskScores[cardIndex.toString()];

      return riskScore;
    } catch (error) {
      console.error("Error fetching risk score:", error);
      return 0; // Default risk score in case of an error
    }
  };

  const updateTemporaryAllocations = (index, newAmount) => {
    const updatedTemporaryAllocations = [...temporaryAllocationAmount];
    updatedTemporaryAllocations[index] = newAmount.toString();
    setTemporaryAllocationAmount(updatedTemporaryAllocations);
  };

  const handleCashAllocationChange = (index, newAmount) => {
    const updatedTemporaryAllocations = [...temporaryAllocationAmount];
    updatedTemporaryAllocations[index] = newAmount.toString();

    const sumOfAllocations = updatedTemporaryAllocations.reduce(
      (total, allocation) => total + parseFloat(allocation),
      0
    );

    const remainingCashAllocation = Math.max(0, 100 - sumOfAllocations);

    if (index === 0) {
      updatedTemporaryAllocations[0] = Math.max(
        0,
        remainingCashAllocation
      ).toString();
    } else if (remainingCashAllocation === 0 && sumOfAllocations !== 0) {
      updatedTemporaryAllocations[0] = "0";

      const totalAllocationExceptCurrent =
        sumOfAllocations - parseFloat(updatedTemporaryAllocations[index]);

      for (let i = 1; i < cards.length; i++) {
        updatedTemporaryAllocations[i] = (
          parseFloat(updatedTemporaryAllocations[i]) /
          totalAllocationExceptCurrent
        ).toString();
      }
    }

    setTemporaryAllocationAmount(updatedTemporaryAllocations);

    setCards((prevCards) => {
      const updatedCards = prevCards.map((card, i) => {
        if (i === index) {
          return { ...card, allocation_amount: newAmount };
        }
        return card;
      });
      return updatedCards;
    });
  };

  const handleCardClick = async (card) => {
    setSelectedCard(card);

    const riskScore = await fetchRiskScore(card.card_index);
    setSelectedCard((prevCard) => ({ ...prevCard, risk_score: riskScore }));
  };

  const handleSaveClick = () => {
    const enteredAllocation = parseFloat(temporaryAllocationAmount[0]);

    if (
      isNaN(enteredAllocation) ||
      enteredAllocation < 0 ||
      enteredAllocation > cashAllocation
    ) {
      alert("Kindly enter a non-negative value");
      return;
    }

    // Calculate the sum of all allocations
    const sumOfAllocations = temporaryAllocationAmount.reduce(
      (total, allocation) => total + parseFloat(allocation),
      0
    );

    // Check if the sum exceeds 100%

    const totalAllocationExceptCurrent = temporaryAllocationAmount
      .slice(1) // Exclude card_index 0
      .reduce((total, allocation) => total + parseFloat(allocation), 0);

    const remainingCashAllocation = Math.max(
      0,
      100 - totalAllocationExceptCurrent
    );

    let updatedAllocations;

    if (remainingCashAllocation === 0) {
      const totalExcludingSelected = temporaryAllocationAmount
        .slice(1) // Exclude card_index 0
        .reduce((total, allocation, index) => {
          if (index !== selectedCard.card_index) {
            return total + parseFloat(allocation);
          }
          return total;
        }, 0);

      // Calculate the adjustment factor to ensure other allocations sum to 100%
      const adjustmentFactor = 1;

      updatedAllocations = temporaryAllocationAmount.map(
        (allocation, index) => {
          if (index === 0) {
            return "0";
          } else if (index === selectedCard.card_index) {
            const normalizedAllocation =
              parseFloat(allocation) * adjustmentFactor;
            return normalizedAllocation.toString();
          } else {
            const normalizedAllocation =
              parseFloat(allocation) * adjustmentFactor;
            return normalizedAllocation.toString();
          }
        }
      );
    } else {
      const totalExcludingSelected = temporaryAllocationAmount
        .slice(1) // Exclude card_index 0
        .reduce((total, allocation, index) => {
          if (index !== selectedCard.card_index) {
            return total + parseFloat(allocation);
          }
          return total;
        }, 0);

      const normalizationFactor = 1;

      updatedAllocations = temporaryAllocationAmount.map(
        (allocation, index) => {
          if (index === 0) {
            return remainingCashAllocation.toString();
          } else if (index === selectedCard.card_index) {
            return parseFloat(allocation).toString();
          } else {
            const normalizedAllocation =
              parseFloat(allocation) * (1 / normalizationFactor);
            return normalizedAllocation.toString();
          }
        }
      );
    }
    // Calculate the sum of all allocations except index 0
    const sumOfAllocationsExceptIndex0 = temporaryAllocationAmount
      .slice(1) // Exclude card_index 0
      .reduce((total, allocation) => total + parseFloat(allocation), 0);

    if (sumOfAllocationsExceptIndex0 > 100) {
      // Handle the error (e.g., display a message to the user)
      alert("Total allocation for cards cannot exceed 100%");
      return;
    }

    setCards((prevCards) => {
      const updatedCards = prevCards.map((card, i) => {
        return {
          ...card,
          allocation_amount: parseFloat(updatedAllocations[i]),
        };
      });
      return updatedCards;
    });

    setTemporaryAllocationAmount(updatedAllocations);
    setSelectedCard(null);
    const newSumAllocationRisk = calculateSumAllocationRisk();
    setSumAllocationRisk(newSumAllocationRisk);
  };

  const getColorForAllocation = (allocation) => {
    if (!isNaN(allocation)) {
      if (allocation >= 0 && allocation <= 10) {
        return `#89C8FF`;
      } else if (allocation > 10 && allocation <= 20) {
        return `#66B8FF`;
      } else if (allocation > 20 && allocation <= 30) {
        return `#42A7FF`;
      } else if (allocation > 30 && allocation <= 40) {
        return `#1E97FF`;
      } else if (allocation > 40 && allocation <= 50) {
        return `#0086F9`;
      } else if (allocation > 50 && allocation <= 60) {
        return `#0072D6`;
      } else if (allocation > 60 && allocation <= 70) {
        return `#005FB2`;
      } else if (allocation > 70 && allocation <= 80) {
        return `#004C8E`;
      } else if (allocation > 80 && allocation <= 90) {
        return `#00396B`;
      } else if (allocation > 90 && allocation <= 100) {
        return `#002647`;
      }
    }
    return "#363636"; // Default color for non-numeric or out-of-range values
  };

  const doughnutChartData = {
    labels: cards.map((card) => card.card_name),
    datasets: [
      {
        data: cards.map((card) => card.allocation_amount),
        backgroundColor: cards.map((card) =>
          getColorForAllocation(card.allocation_amount)
        ),
        hoverBackgroundColor: "#0056b3",
      },
    ],
  };

  const chartOptions = {
    legend: {
      display: true,
    },
    cutout: 50,
    responsive: true,
    datalabels: {
      color: "#fff", // Label color
      display: true, // Display labels
    },
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    borderColor: "transparent",
    onClick: (event, elements) => {
      if (elements && elements.length > 0) {
        const clickedIndex = elements[0].index;
        if (cards[clickedIndex]) {
          onCardClick(cards[clickedIndex]);
        }
      }
    },
  };

  const handleSavePortfolio = () => {
    // Logic for saving the portfolio data
  };

  const handleModify = () => {
    // Logic for modifying portfolio data
  };

  const cardStyle = (card) => {
    const allocation = parseFloat(card.allocation_amount);
    const backgroundColor =
      allocation >= 100 && !isNaN(allocation)
        ? `#002647`
        : allocation >= 90 && !isNaN(allocation)
        ? `#00396B`
        : allocation >= 80 && !isNaN(allocation)
        ? `#004C8E`
        : allocation >= 70 && !isNaN(allocation)
        ? `#005FB2`
        : allocation >= 60 && !isNaN(allocation)
        ? `#0072D6`
        : allocation >= 50 && !isNaN(allocation)
        ? `#0086F9`
        : allocation >= 40 && !isNaN(allocation)
        ? `#1E97FF`
        : allocation >= 30 && !isNaN(allocation)
        ? `#42A7FF`
        : allocation >= 20 && !isNaN(allocation)
        ? `#66B8FF`
        : allocation > 0 && !isNaN(allocation)
        ? `#89C8FF`
        : "#363636";

    return {
      // border: "1px solid #ccc",
      // padding: "10px",
      // marginBottom: "10px",
      // fontSize: "1rem",
      // cursor: "pointer",
      // height: "150px",
      // transition: "box-shadow 0.6s ease",
      backgroundColor: backgroundColor,
    };
  };

  // Define the headingStyle object
  const headingStyle = {
    color: "#fff",
    fontSize: "1.2rem",
    marginBottom: "5px",
  };

  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        borderColor: "#007bff",
      },
      {
        label: "Dataset 2",
        data: [28, 48, 40, 19, 86, 27],
        fill: false,
        borderColor: "#28a745",
      },
      {
        label: "Dataset 3",
        data: [45, 25, 40, 58, 36, 67],
        fill: false,
        borderColor: "#ffc107",
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("doughnut1"); // Default active tab
  const [riskScore1, setRiskScore1] = useState(50);
  const [riskScore2, setRiskScore2] = useState(50);
  const [returnfield1, setReturnField1] = useState(50);
  const [returnfield2, setReturnField2] = useState(50);
  const [sharpratiofield1, setSharpRatioField1] = useState(50);
  const [sharpratiofield2, setSharpRatioField2] = useState(50);
  const dummyDoughnutChartData = {
    labels: ["Label 1", "Label 2", "Label 3"],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ["#007bff", "#28a745", "#ffc107"],
        hoverBackgroundColor: "#0056b3",
      },
    ],
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const [clPortfolioReturns, setClPortfolioReturns] = useState([]);
  const [optPortfolioReturns, setOptPortfolioReturns] = useState([]);
  const [date, setDate] = useState([]);
  const [clRiskScore, setClRiskScore] = useState(0);
  const [optRiskScore, setOptRiskScore] = useState(0);
  const [clTotalReturn, setClTotalReturn] = useState(0);
  const [optTotalReturn, setOptTotalReturn] = useState(0);
  const [optSharpe, setOptSharpe] = useState(0);
  const [orgSharpe, setOrgSharpe] = useState(0);

  const handleApiCall = async () => {
    const proxyApiUrl = "http://localhost:8000/proxy_api2/"; // Django proxy URL
    const externalApiUrl = "http://46.101.121.174/optimize"; // External API URL

    const allocations = {};
    temporaryAllocationAmount.forEach((allocation, index) => {
      if (
        index !== 1 &&
        index !== 10 &&
        index !== 11 &&
        index !== 12 &&
        index !== 13 &&
        index !== 14
      ) {
        allocations[index.toString()] = parseFloat(allocation);
      }
    });

    const payload = {
      allocations: allocations,
      risk_limit: riskLimit.toString(),
    };

    try {
      const csrftoken = getCookie("csrftoken");

      const response = await axios.post(proxyApiUrl, payload, {
        headers: {
          "X-CSRFToken": csrftoken,
          "Content-Type": "application/json",
        },
      });

      const {
        cl_portfolio_returns,
        opt_portfolio_returns,
        date,
        cl_risk_score,
        opt_risk_score,
        cl_total_return,
        opt_total_return,
        opt_sharpe,
        org_sharpe,
      } = response.data;

      // Store the data in state variables
      setClPortfolioReturns(cl_portfolio_returns);
      setOptPortfolioReturns(opt_portfolio_returns);
      setDate(date);
      setClRiskScore(cl_risk_score);
      setOptRiskScore(opt_risk_score);
      setClTotalReturn(cl_total_return);
      setOptTotalReturn(opt_total_return);
      setOptSharpe(opt_sharpe);
      setOrgSharpe(org_sharpe);

      setApiResponse(response.data);
    } catch (error) {
      console.error("Error calling API:", error);
      setApiResponse(null);
    }
  };

  const apexOptions = {
    chart: {
      type: "donut",
      height: "100%", // You can set the desired height
      events: {
        dataPointSelection: (event, chartContext, config) => {
          if (config.dataPointIndex !== undefined) {
            const clickedIndex = config.dataPointIndex;
            if (cards[clickedIndex]) {
              onCardClick(cards[clickedIndex]);
            }
          }
        },
      },
    },
    labels: doughnutChartData.labels,
    legend: {
      show: false,
    },
    dataLabels: {
      style: {
        colors: ["#fff"],
      },
      enabled: true,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
    },

    plotOptions: {
      pie: {
        size: "70%",
        donut: {
          size: "30%", // cutoutPercentage
        },
      },
    },
    stroke: {
      colors: ["transparent"],
    },
    colors: doughnutChartData.datasets[0].backgroundColor,
    events: {
      dataPointSelection: (event, chartContext, config) => {
        if (config.dataPointIndex !== undefined) {
          const clickedIndex = config.dataPointIndex;
          if (cards[clickedIndex]) {
            onCardClick(cards[clickedIndex]);
          }
        }
      },
    },
  };

  const series = doughnutChartData.datasets[0].data;

  return (
    <div className="flex flex-col md:flex-row justify-between  gap-10 w-full p-3 md:p-10 lg:p-20  ">
      <div className="bg-secondaryBg grid relative grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 p-5 gap-2 w-full max-w-6xl overflow-y-auto">
        {cards.map((card, index) => (
          <div
            key={card.card_index}
            className="px-2 py-10 min-w-fit text-xs flex items-center justify-center text-center flex-col h-44 md:h-full cursor-pointer"
            style={
              selectedCard === card ? { ...cardStyle(card) } : cardStyle(card)
            }
            // className={`card-container grid-item ${
            //   selectedCard === card ? "fillAnimation" : ""
            // }`}
            onClick={() => handleCardClick(card)}
          >
            <h3 className="text-sm mt-3 md:text-xl font-serif  tracking-wider">
              {card.card_name}
            </h3>

            {/* Display calculated temporary allocation */}
            <p className="mt-2">
              Temporary Allocation: {temporaryAllocationAmount[index]}
            </p>

            <p className={selectedCard === card ? `mt-1` : `invisible mt-1`}>
              Risk Score: {selectedCard?.risk_score}
            </p>
          </div>
        ))}

        {/* Modal that opens after selection */}

        {selectedCard && (
          <Backdrop>
            <div
              className={`flex flex-col h-full justify-start md:justify-center items-center  ${
                selectedCard ? "open" : ""
              }`}
              onClick={() => setSelectedCard(null)}
            >
              <div
                className="flex flex-col font-thin min-w-fit text-center justify-start md:justify-center items-center gap-6 p-4 w-full leading-8 md:w-3/4"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="close-button"
                  onClick={() => setSelectedCard(null)}
                >
                  <XMarkIcon className="h-6 w-6 absolute top-5 right-5 hover:opacity-70" />
                </button>

                <h3 className="text-3xl font-serif tracking-wider">
                  {selectedCard.card_name}
                </h3>
                <p className="w-full md:w-3/4 ">
                  {selectedCard.card_description}
                </p>

                {/* <p>CARD INDEX {selectedCard.card_index}</p> */}

                <div className="text-center items-center font-serif font-light grid grid-cols-2 gap-3 tracking-wider">
                  ALLOCATION
                  <input
                    type="number"
                    placeholder="Allocation Amount 0-100"
                    value={temporaryAllocationAmount[selectedCard.card_index]}
                    onChange={(e) => {
                      const updatedAllocations = [...temporaryAllocationAmount];
                      updatedAllocations[selectedCard.card_index] =
                        e.target.value;
                      setTemporaryAllocationAmount(updatedAllocations);
                    }}
                    min="0"
                    max={cashAllocation}
                    className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                  />
                  RISK SCORE
                  <p className="bg-transparent text-left border border-gray-400 border-opacity-25  w-32 p-3">
                    {selectedCard.risk_score?.toFixed(2)}
                  </p>
                </div>

                <button
                  className="text-right font-normal text-xl mt-3 hover:opacity-70"
                  onClick={handleSaveClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </Backdrop>
        )}
      </div>

      <div className="flex flex-col items-center justify-start w-3/4 gap-16 font-serif">
        <Chart
          height={350}
          width={350}
          type="donut"
          series={series}
          options={apexOptions}
        />
        {/* <NewDoughnut
          height={300}
          width={300}
          data={doughnutChartData}
          options={chartOptions}
        /> */}

        {
          <button
            className="tracking-widest uppercase hover:opacity-70"
            onClick={handleApiCall}
          >
            Hit optimize API
          </button>
        }

        {/* Display API Response */}
        {apiResponse && (
          <div className="api-response transition-opacity duration-150">
            <h3>
              Success! Click the Portfolio Builder for analysis or click the
              Build button to save
            </h3>
            {console.log(apiResponse)}
            {/* {JSON.stringify(apiResponse, null, 2)} */}
          </div>
        )}

        <div className="grid grid-cols-2 w-2/4 items-center gap-10 font-serif font-light">
          <span className="text-xl">Risk Score</span>
          <input
            type="text"
            id="risk-score"
            value={apiResponse?.cl_risk_score.toFixed(2)} // Format to 2 decimal places
            readOnly // Make the input read-only
            className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
          />
          <span className="text-xl">Risk Limit</span>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{
                width: `${apiResponse?.cl_risk_score.toFixed(2) || 0}%`,
              }}
            ></div>
          </div>
        </div>

        <RightSidebar
          cards={cards}
          yourPortfolioChartOptions={apexOptions}
          yourPortfolio={series}
          onCardClick={handleCardClick}
          apiResponse={apiResponse}
        />
      </div>
    </div>
  );
};

export default Cards;
