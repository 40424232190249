import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password } from "../actions/auth";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    reset_password(email);
    setRequestSent(true);
  };

  if (requestSent) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-[90vh] flex flex-col items-center justify-center p-5">
      <div className="bg-white text-black p-10 w-full md:w-2/6   justify-center flex flex-col gap-6">
        <h1 className="text-4xl font-bold">Reset Password</h1>
        <form className="flex flex-col gap-3" onSubmit={(e) => onSubmit(e)}>
          <div>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              className="bg-none border-b focus:border-b-2 py-4 px-1 w-full"
              required
            />
          </div>
          <button
            className="flex flex-row gap-2 whitespace-nowrap justify-between items-end text-xl mt-4 p-3 w-full md:w-1/6 min-w-fit  hover:bg-primaryBg hover:text-white transition duration-300"
            type="submit"
          >
            Reset Password
            <ArrowRightIcon className="h-6 w-6  hover:opacity-70" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default connect(null, { reset_password })(ResetPassword);
