import React, { useState, useEffect } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

const PortfolioList = () => {
  const [uniquePortfolioNumbers, setUniquePortfolioNumbers] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    const jwtToken = localStorage.getItem("access");
    const userEmail = localStorage.getItem("userEmail"); // Assuming you store the user's email in 'userEmail'

    axios
      .get("http://localhost:8000/api/portfolios/", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      })
      .then((response) => {
        // Filter and extract distinct portfolio numbers for the current user
        const portfoliosForUser = response.data.filter(
          (portfolio) => portfolio.email === userEmail
        );
        const distinctPortfolioNumbers = [
          ...new Set(
            portfoliosForUser.map((portfolio) => portfolio.portfolio_number)
          ),
        ];
        setUniquePortfolioNumbers(distinctPortfolioNumbers);
        setPortfolioData(portfoliosForUser);
      })
      .catch((error) => {
        console.error("Error fetching portfolios:", error);
      });
  }, []);

  const colors = ["#007bff", "#28a745", "#ffc107", "#dc3545"];

  const getChartDataset = (portfolio) => {
    return {
      labels: portfolio.map((card) => card.card_name),

      datasets: [
        {
          data: portfolio.map((card) => card.allocation_amount),
          backgroundColor: portfolio.map((card) =>
            getColorForAllocation(card.allocation_amount)
          ),
          hoverBackgroundColor: "#0056b3",
        },
      ],
    };
  };

  const chartOptions = {
    legend: {
      display: true,
    },
    cutout: 20,
    responsive: true,
    datalabels: {
      color: "#fff", // Label color
      display: true, // Display labels
    },
    maintainAspectRatio: false,
    cutoutPercentage: 100,
    borderColor: "transparent",
  };

  const getColorForAllocation = (allocation) => {
    if (allocation >= 100 && !isNaN(allocation)) {
      return `#002647`;
    } else if (allocation >= 90 && !isNaN(allocation)) {
      return `#00396B`;
    } else if (allocation >= 80 && !isNaN(allocation)) {
      return `#004C8E`;
    } else if (allocation >= 70 && !isNaN(allocation)) {
      return `#005FB2`;
    } else if (allocation >= 60 && !isNaN(allocation)) {
      return `#0072D6`;
    } else if (allocation >= 50 && !isNaN(allocation)) {
      return `#0086F9`;
    } else if (allocation >= 40 && !isNaN(allocation)) {
      return `#1E97FF`;
    } else if (allocation >= 30 && !isNaN(allocation)) {
      return `#42A7FF`;
    } else if (allocation >= 20 && !isNaN(allocation)) {
      return `#66B8FF`;
    } else if (allocation > 0 && !isNaN(allocation)) {
      return `#89C8FF`;
    } else {
      return "#363636";
    }
  };

  return (
    <div>
      <h1 className="font-serif text-4xl text-center m-14">Saved Portfolios</h1>
      {console.log(uniquePortfolioNumbers)}
      <div className="flex flex-col items-center gap-10 flex-wrap justify-center p-12 bg-secondaryBg md:flex-row">
        {uniquePortfolioNumbers.map((portfolioNumber) => {
          const portfolio = portfolioData
            .filter((item) => item.portfolio_number === portfolioNumber)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by date, latest first

          const riskPercentage =
            portfolio.length > 0 ? portfolio[0].risk_percentage : 10;

          return (
            <div
              key={portfolioNumber}
              className="border-2 relative w-full md:w-auto border-stone-300 border-opacity-30 px-4 py-8 flex flex-col md:flex-row items-center justify-center gap-3"
            >
              <div className="w-44">
                <Doughnut
                  data={getChartDataset(portfolio)}
                  options={chartOptions}
                />
              </div>

              <div className="font-serif">
                <h3 className="text-sm  font-extralight">
                  Portfolio Number{""}
                  <span className=" ms-2 p-2  "> {portfolioNumber}</span>
                </h3>

                <p className="text-sm font-extralight mt-5 ">
                  Risk Score:{""}
                  <span className=" ms-2 p-2 "> {riskPercentage}</span>
                </p>
              </div>
              {portfolio.length > 0 && (
                <p className="absolute bottom-3 right-3 text-xs opacity-40">
                  Date Created:{"  "}
                  {new Date(portfolio[0].created_at).toLocaleDateString()}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PortfolioList;
