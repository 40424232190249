import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import Home from "./containers/Home";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Activate from "./containers/Activate";
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordConfirm from "./containers/ResetPasswordConfirm";
import Cards from "./containers/Cards";
import store from "./store";
import Layout from "./hocs/Layout";
import "./dark-theme.css";
import PortfolioList from "./containers/PortfolioList";
import RightSidebar from "./containers/RightSidebar";
import AuthSelect from "./containers/AuthSelect";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/authSelect" component={AuthSelect} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              component={ResetPasswordConfirm}
            />
            <Route exact path="/activate/:uid/:token" component={Activate} />
            <Route exact path="/cards" component={Cards} />
            <Route exact path="/PortfolioList" component={PortfolioList} />
            <Route exact path="/RightSidebar" component={RightSidebar} />
          </Switch>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
