// Backdrop.js
import React from "react";

function Backdrop({ onClick, position = "absolute", children }) {
  return (
    <div
      className={`top-0 left-0 w-full h-full bg-secondaryBg  z-[1000] ${position}`}
      onClick={onClick}
    >
      <div className="flex flex-col w-full items-center justify-center h-full">
        {children}
      </div>
    </div>
  );
}

export default Backdrop;
