// PortfolioBuilderPopup.js

import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import "./PortfolioBuilderPopup.css"; // Create a CSS file for styling
import Cards from "./Cards";
import Chart from "react-apexcharts";

import {
  Chart as OldChart,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { CategoryScale } from "chart.js";
import { useEffect } from "react";
import Backdrop from "../components/Backdrop";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
OldChart.register(CategoryScale);
OldChart.register(LinearScale);
OldChart.register(PointElement);
OldChart.register(LineElement);

const defaultCards = [
  {
    card_index: 0,
    card_name: "Cash",
    card_description: "Cash available for investment.",
    allocation_amount: 100,
    risk_score: 0,
  },
  {
    card_index: 1,
    card_name: "Equity & Derivatives",
    card_description:
      "Equity refers to ownership in a company, represented by stocks or shares. It is a crucial component of a diversified investment portfolio and can play an important role in long-term wealth creation.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 2,
    card_name: "Investment Grade Bonds",
    card_description:
      "Investment grade bonds are debt securities issued by corporations or governments with a credit rating of BBB- or higher by a credit rating agency.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 3,
    card_name: "Gold",
    card_description: "Gold can be an effective way to diversify a portfolio.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 4,
    card_name: "Money Market",
    card_description:
      "A money market is a segment of the financial market where financial instruments with high liquidity and low volatility, such as Treasury bills, certificates of deposit, and commercial paper, are traded.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 5,
    card_name: "NASDAQ",
    card_description:
      "NASDAQ is a stock exchange based in the United States. It is known for listing technology and internet-based companies, but it also lists companies from other industries. Investing in NASDAQ-listed stocks can offer the opportunity for growth and diversification for investors.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 6,
    card_name: "Real Estate",
    card_description:
      "A Real Estate Investment Trust (REIT) is a type of investment vehicle that invests in income-producing real estate assets, such as office buildings, shopping centers, apartments, hotels etc. REITs can provide investors with a regular income stream and the potential for long-term capital appreciation.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 7,
    card_name: "Emerging Equities",
    card_description:
      "Emerging equities refer to stocks of companies that are based in emerging market countries, such as Brazil, China, and India. It can provide investors with the potential for higher returns compared to developed market equities, due to the higher growth potential of the economies and companies in these countries.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 8,
    card_name: "Commodities",
    card_description:
      "Commodities are raw materials or primary products that are used in the production of goods and services. Examples of commodities include precious metals, energy products, agricultural products, and industrial metals. Investing in commodities can provide investors with the potential for diversification.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 9,
    card_name: "High Yield Bonds",
    card_description:
      "High yield bonds are issued by companies with lower credit ratings, meaning that there is a higher risk of default compared to investment-grade bonds. These bonds pay higher yields compared to investment-grade bonds to compensate for the increased risk of default.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 10,
    card_name: "Cryptocurrency",
    card_description:
      "Cryptocurrency is a type of digital or virtual currency that uses cryptography to secure transactions and control the creation of new units. Bitcoin is the most well-known cryptocurrency. Investing in them can provide the potential for high returns, but it also involves a high level of risk.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 11,
    card_name: "Private Equity",
    card_description:
      "Private equity refers to investment in companies that are not publicly traded on stock exchanges. The investment horizon for private equity is typically longer than for public equity, and the returns are often higher, although the risk is also higher.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 12,
    card_name: "Volatility Index",
    card_description:
      "The Volatility Index (VIX) is a financial index that is used as a measure of market volatility and investor sentiment. The VIX reflects the market's expectation of volatility in the S&P 500 Index over the next 30 days.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 13,
    card_name: "Forex",
    card_description:
      "Forex, also known as the foreign exchange market, is a decentralized global market where all the world's currencies are traded.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 14,
    card_name: "TIPS",
    card_description:
      "Treasury Inflation-Protected Securities (TIPS) are a type of U.S. Treasury bond that provides protection against inflation. TIPS are considered a relatively low-risk investment, as they are backed by the full faith and credit of the U.S. government.",
    allocation_amount: 0,
    risk_score: 0,
  },
  {
    card_index: 15,
    card_name: "ESG",
    card_description:
      "Environmental, Social, and Governance (ESG) investing refers to an investment strategy that considers a company's impact on the environment, its treatment of employees, and its overall corporate governance practices.",
    allocation_amount: 0,
    risk_score: 0,
  },
];

const PortfolioBuilderPopup = ({
  onClose,
  apiResponse = {},
  yourPortfolioChartOptions,
  yourPortfolio,
}) => {
  const [activeTab, setActiveTab] = useState("doughnut1"); // Default active tab
  const [riskScore1, setRiskScore1] = useState(apiResponse?.cl_risk_score);
  const [riskScore2, setRiskScore2] = useState(apiResponse?.opt_risk_score);
  const [returnfield1, setReturnField1] = useState(
    apiResponse?.cl_total_return
  );
  const [returnfield2, setReturnField2] = useState(
    apiResponse?.opt_total_return
  );
  const [sharpratiofield1, setSharpRatioField1] = useState(50);
  const [sharpratiofield2, setSharpRatioField2] = useState(50);
  const [clPortfolioReturns, setClPortfolioReturns] = useState([]);
  const [optPortfolioReturns, setOptPortfolioReturns] = useState([]);
  const [clAllocations, setClAllocations] = useState([]);
  const [optAllocations, setOptAllocations] = useState([]);
  const [chartDates, setChartDates] = useState([]);

  useEffect(() => {
    setClPortfolioReturns(apiResponse?.cl_portfolio_returns);
    setOptPortfolioReturns(apiResponse?.opt_portfolio_returns);
    setClAllocations(apiResponse?.cl_allocation);
    setOptAllocations(apiResponse?.opt_allocation);
    setChartDates(apiResponse?.date);
  }, [apiResponse]);

  console.log(yourPortfolio);

  // Similar state management for other fields and charts
  const dummyDoughnutChartData = {
    labels: ["Label 1", "Label 2", "Label 3"],
    datasets: [
      {
        data: [30, 40, 20],
        backgroundColor: ["#007bff", "#28a745", "#ffc107"],
        hoverBackgroundColor: "#0056b3",
      },
    ],
  };
  // Dummy data for line chart
  const lineChartData = {
    labels: chartDates, // Use chartDates as the x-axis labels
    datasets: [
      {
        label: "CL Portfolio Returns",
        data: clPortfolioReturns, // Use clPortfolioReturns as the data for this dataset
        fill: false,
        borderColor: "#007bff",
      },
      {
        label: "Optimized Portfolio Returns",
        data: optPortfolioReturns, // Use optPortfolioReturns as the data for this dataset
        fill: false,
        borderColor: "#28a745",
      },
      // Add additional datasets as needed
    ],
  };

  const handleSavePortfolio = () => {
    // Logic for saving the portfolio data
  };

  const handleModify = () => {
    // Logic for modifying portfolio data
  };

  const chartOptions = {
    legend: {
      display: true,
    },
    cutout: 20,
    responsive: true,
    datalabels: {
      color: "#fff", // Label color
      display: true, // Display labels
    },
    maintainAspectRatio: false,
    cutoutPercentage: 100,
    borderColor: "transparent",
  };

  // const options = {
  //   plugins: {
  //     tooltip: {
  //       enabled: true,
  //       intersect: false,
  //       mode: "nearest",
  //       callbacks: {
  //         title: () => "title",
  //         label: (item) => item.parsed + "%",
  //       },
  //     },
  //   },
  // };

  // const data = {
  //   labels: apiResponse?.date,
  //   datasets: [
  //     {
  //       label: "Your Porfolio Returns",
  //       data: apiResponse?.cl_portfolio_returns?.map((label) =>
  //         label?.toFixed(3)
  //       ),
  //       borderColor: "#89C8FF",
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //     {
  //       label: "Optimized Porfolio Returns",
  //       data: apiResponse?.opt_portfolio_returns?.map((label) =>
  //         label?.toFixed(3)
  //       ),
  //       borderColor: "#005FB2",
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //     },
  //   ],
  // };

  const data = {
    labels: apiResponse?.date,
    series: [
      {
        name: "Original Portfolio",
        data: apiResponse?.cl_portfolio_returns?.map((label) =>
          parseFloat(label?.toFixed(3))
        ),
      },
      {
        name: "Optimized Portfolio",
        data: apiResponse?.opt_portfolio_returns?.map((label) =>
          parseFloat(label?.toFixed(3))
        ),
      },
    ],
  };

  const options = {
    colors: ["#89C8FF", "#42A7FF"],
    chart: {
      type: "line",
    },
    xaxis: {
      categories: data.labels,
      tickAmount: 6,
    },
    legend: {
      show: true,
      position: "right", // Set the legend position to 'right'
    },
    grid: {
      show: true,
      borderColor: "#FFFFFF",

      row: {
        colors: ["#e6ecf5"],
      },
      column: {
        colors: ["#e6ecf5"],
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <Backdrop position="fixed">
      <div className="overflow-auto min-h-screen min-w-full">
        <button onClick={onClose}>
          <XMarkIcon className="h-12 w-12 text-gray-400 absolute top-10 right-10 font-extralight  hover:opacity-70" />
        </button>

        <div
          className="flex flex-col font-thin min-w-fit min-h-screen  text-center justify-start md:justify-center items-center gap-6 p-10  leading-8 w-full"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Tab Content */}

          <div className="flex flex-col lg:flex-row justify-center items-start gap-20 w-full p-3 md:p-10 lg:p-20  ">
            <div className="flex flex-col  items-center justify-start  gap-16 font-serif p-20 lg:bg-primaryBg lg:w-2/4">
              <h2 className=" text-4xl font-extralight">Your Portfolio</h2>
              <div className="h-72">
                <Chart
                  series={clAllocations}
                  options={yourPortfolioChartOptions}
                  height={350}
                  width={350}
                  type="donut"
                />
              </div>

              {/* Risk Score Fields */}
              <div className="grid grid-cols-2  items-center gap-10 font-serif font-light">
                <span className="text-xl">Risk Score</span>
                <input
                  type="text"
                  value={riskScore1}
                  onChange={(e) => setRiskScore1(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                />
                <span className="text-xl">Total Returns</span>
                <input
                  type="text"
                  value={returnfield1?.toFixed(2)}
                  onChange={(e) => setReturnField1(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                />

                {/* <span className="text-xl">Ratio</span>
                <input
                  type="text"
                  value={sharpratiofield1}
                  onChange={(e) => setSharpRatioField1(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                /> */}
              </div>
            </div>

            <div className="flex flex-col items-center justify-start gap-16 font-serif p-20 lg:w-2/4">
              <h2 className=" text-4xl font-extralight">Optimized Portfolio</h2>
              <div className="h-72">
                <Chart
                  series={optAllocations}
                  options={yourPortfolioChartOptions}
                  height={350}
                  width={350}
                  type="donut"
                />
              </div>

              {/* Risk Score Fields */}
              <div className="grid grid-cols-2  items-center gap-10 font-serif font-light">
                <span className="text-xl">Risk Score</span>
                <input
                  type="text"
                  value={riskScore2}
                  onChange={(e) => setRiskScore2(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                />
                <span className="text-xl">Total Returns</span>
                <input
                  type="text"
                  value={returnfield2?.toFixed(2)}
                  onChange={(e) => setReturnField2(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                />

                {/* <span className="text-xl">Ratio</span>
                <input
                  type="text"
                  value={sharpratiofield1}
                  onChange={(e) => setSharpRatioField2(e.target.value)}
                  readOnly // Make the input read-only
                  className="bg-transparent border border-gray-400 border-opacity-25  w-32 p-3"
                /> */}
              </div>
            </div>
          </div>

          {/* Total Return Fields */}
        </div>

        {/* SECOND PAGE */}
        <div className="flex flex-col max-h-screen bg-white text-gray-700 font-thin min-w-full text-center justify-start md:justify-center items-center gap-6 p-10  leading-8 w-full">
          <h2 className=" text-4xl font-extralight m-12">
            Interactive Line Graph
          </h2>
          <div className="h-2/4 w-3/4">
            {/* <Line data={data} options={options} /> */}
            <Chart
              series={data.series}
              options={options}
              height={800}
              type="line"
            />
          </div>

          {/* Buttons */}
          <div className="flex md:flex-row flex-col items-center gap-5">
            <button
              className="flex font-serif tracking-widest flex-row gap-2 justify-between items-end text-xl mt-4 p-3 w-full md:w-1/5 min-w-fit  hover:bg-primaryBg hover:text-white transition duration-300"
              onClick={handleSavePortfolio}
            >
              Save Portfolio
              <ArrowRightIcon className="h-6 w-6  hover:opacity-70" />
            </button>

            <button
              className="flex font-serif tracking-widest flex-row gap-2 justify-between items-end text-xl mt-4 p-3 w-full md:w-1/5 min-w-fit  hover:bg-primaryBg hover:text-white transition duration-300"
              onClick={handleModify}
            >
              Modify
              <ArrowRightIcon className="h-6 w-6  hover:opacity-70" />
            </button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default PortfolioBuilderPopup;
