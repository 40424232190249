import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import axios from "axios";

const AuthSelect = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);

    localStorage.setItem("userEmail", email);
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-[90vh] flex flex-col items-center justify-center p-5">
      <div className="bg-white text-black p-10 w-full md:w-3/6 lg:w-2/6  text-center justify-center flex flex-col items-center gap-9">
        <ArrowRightOnRectangleIcon className="h-28 w-28 text-white p-8 rounded-full bg-primaryBg " />

        <div className="flex flex-col gap-5 w-4/5">
          <hr className="w-full" />

          <div className="flex flex-row justify-between font-semibold">
            <p>Continue with LinkedIn</p>
            <ArrowRightIcon className="h-6 w-6 hover:opacity-70 cursor-pointer" />
          </div>

          <div className="flex flex-row justify-between font-semibold">
            <p>Continue with Google</p>
            <ArrowRightIcon className="h-6 w-6 hover:opacity-70 cursor-pointer" />
          </div>

          <div className="flex flex-row justify-between font-semibold">
            <p>Continue with Email</p>
            <Link to="/login">
              <ArrowRightIcon className="h-6 w-6 hover:opacity-70 cursor-pointer" />
            </Link>
          </div>

          <p className="mt-6 text-xs font-bold">
            Don't have an account? <Link to="/signup">Create one</Link>
          </p>

          <p className="text-gray-500 text-xs w-full md:w-3/6 text-center self-center">
            By signing up I agree to the Terms and Conditions & Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(AuthSelect);
