import React, { useEffect, useRef, useState } from "react";

function DropdownButton({ links, auth }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-center" ref={dropdownRef}>
      <button
        id="dropdownDefaultButton"
        className="flex flex-row font-semibold items-center hover:opacity-70"
        type="button"
        onClick={toggleDropdown}
      >
        {auth ? `Hi, ${localStorage.getItem("userName")}` : `Account`}
        <svg
          className={`w-2.5 h-2.5 ml-2.5 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          id="dropdown"
          className="z-10 absolute mt-2 py-1 text-sm text-black"
          onClick={closeDropdown}
        >
          <ul
            className="bg-white shadow w-44 py-4 px-5 flex flex-col  gap-2 "
            aria-labelledby="dropdownDefaultButton"
          >
            {links}
          </ul>
        </div>
      )}
    </div>
  );
}

export default DropdownButton;
